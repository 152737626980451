import { FileCollaboration, Layout, useFilePage } from '@hubql/app-core';
import { SandpackProvider } from '@codesandbox/sandpack-react';
import { sandpackDark } from '@codesandbox/sandpack-themes';
import { ReactFlowProvider } from 'reactflow';

import { Loading } from '@hubql/hubqlkit';

export const FileDetailPage = () => {
  const { loading, data, slugProp, workspaceData, hasFileAccess } =
    useFilePage();

  if (loading) return <Loading />;

  return (
    <SandpackProvider theme={sandpackDark}>
      <ReactFlowProvider>
        <Layout slugProp={slugProp} workspaceData={workspaceData}>
          {hasFileAccess ? (
            <FileCollaboration
              //@ts-expect-error
              data={data}
            />
          ) : (
            <div className="flex flex-col w-fill h-[80vh] items-center justify-center gap-4">
              <h1 className="text-xl">You do not have access to this file</h1>
              <h2>CODE: UNAUTHORIZED</h2>
            </div>
          )}
        </Layout>
      </ReactFlowProvider>
    </SandpackProvider>
  );
};

export default FileDetailPage;
FileDetailPage.displayName = 'File Detail';
